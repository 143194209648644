'use client';

import React from 'react';
import styles from './styles.module.scss';
import { Carousel } from '@red-digital/bricks';

const carouselData = [
  {
    title: 'Whole Home Wi-Fi',
    content:
      'All plans include a Wi-Fi Modem featuring Wi-Fi 6 technology. Add additional Mesh Wi-Fi Nodes to eliminate dead zones in your home.',
  },
  {
    title: 'Unlimited Standard Data <sup>2</sup>',
    content:
      'You have an unlimited amount of Standard Data. If you use all your Priority Data, Standard Data kicks in automatically.',
  },
  {
    title: 'Jupiter Powered',
    content:
      'Hughesnet plans are powered by JUPITER™ 3, the largest, most advanced commercial communications satellite.',
  },
];

export default function GetTheMost() {
  const handleTabClick = (
    index: number,
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    const tabs = document.querySelectorAll(`.${styles.getTheMostTabs} > div`);
    tabs.forEach((tab) => tab.classList.remove('active-tab'));

    const button = event.currentTarget as HTMLDivElement;
    button.classList.add('active-tab');

    const slidePagination = document.querySelector(
      `.${styles.getTheMost} [aria-label="Go to slide ${index + 1}"]`,
    );

    if (slidePagination) {
      (slidePagination as HTMLElement).click();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (event.key === 'Enter') {
      handleTabClick(index, event);
    }
  };

  return (
    <section className={styles.getTheMost}>
      <div className={styles.container}>
        <div>
          <h2>Get the most from satellite internet with Hughesnet</h2>
          <p>
            Here&rsquo;s how Hughesnet delivers a faster and more reliable
            internet service.
          </p>
          <div className={styles.getTheMostTabs}>
            {carouselData.map((item, index) => (
              <div
                role="button"
                tabIndex={0}
                key={index}
                className={index === 0 ? 'active-tab' : ''}
                onClick={(event) => handleTabClick(index, event)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            ))}
          </div>
          <Carousel
            config={{
              autoplay: {
                enabled: false,
              },
              animationType: 'slide',
              slidesToShow: 1,
              controls: {
                pagination: {
                  enabled: true,
                },
              },
              gap: 0,
              overflow: 'hidden',
            }}
            className="getTheMostCarousel"
          >
            {carouselData.map((item, index) => (
              <div key={index} className={styles.getTheMostSlide}>
                <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                <p dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
