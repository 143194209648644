export default function Phone({ fill = '#DE3700' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.7512 6.02174C6.22052 7.01196 6.0185 8.07615 6 9.06636C6 9.91004 6.11097 10.6072 6.27601 11.3029C6.56909 12.4951 7.08269 13.6134 7.703 14.679C8.38164 15.8527 9.18833 16.9539 10.197 17.8886C10.9497 18.5858 11.7748 19.1534 12.7281 19.5205C13.8108 19.9431 14.9105 20.1266 16.0473 19.9061C16.6334 19.7965 17.184 19.5945 17.6607 19.2459C18.1743 18.8604 18.2838 18.3112 17.9537 17.7606C17.6237 17.21 17.2936 16.6793 16.9635 16.1287C16.578 15.5056 16.1938 14.881 15.7898 14.2764C15.5877 13.9648 15.2947 13.8552 14.9276 13.9833C14.4154 14.1654 13.9559 14.4229 13.6813 14.8995C13.5717 15.083 13.4437 15.12 13.2602 15.0461C13.1506 15.0091 13.0396 14.955 12.9486 14.881C12.8191 14.7715 12.6911 14.6605 12.563 14.5325C11.9769 13.9264 11.5173 13.2122 11.0962 12.478C10.7846 11.9104 10.51 11.3043 10.3265 10.6812C10.2525 10.4606 10.2155 10.2216 10.2525 9.96552C10.2895 9.78199 10.3991 9.69094 10.6011 9.67244C10.6566 9.67244 10.7291 9.67244 10.7846 9.65395C11.2242 9.57997 11.5728 9.35944 11.9029 9.08486C12.2699 8.79178 12.3254 8.4802 12.1049 8.07615C11.8488 7.58105 11.5543 7.08594 11.2797 6.59083C10.8757 5.8937 10.473 5.21506 10.069 4.53643C9.84847 4.16936 9.5184 3.96734 9.07878 4.00433C8.85968 4.02282 8.67615 4.09681 8.49261 4.20635C7.72292 4.62748 7.17233 5.25205 6.7512 6.02174Z"
        fill={fill}
      ></path>
    </svg>
  );
}
