'use client';

import { useContext } from 'react';
import Link from 'next/link';
import HughesNetLogo from '~/components/HughesNetLogo';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';
import Phone from '~/components/Phone';

export default function Masthead() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <header>
      <div className="container">
        <Link href="/">
          <HughesNetLogo />
        </Link>
        <div>
          <span className="call">Call to Order</span>
          <span className="phone">
            <Phone />
            <PhoneNumber dashes />
          </span>
        </div>
        <a href={`tel:${fuseNumber || defaultFuseNumber}`}>
          <Phone fill="#005dac" />
          Call Now
        </a>
      </div>
    </header>
  );
}
