export default function HughesNetLogo({
  width = 180,
  height = 51,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className="hughesnet-logo"
    >
      <path
        d="M1.47166 20.7514L4.88565 18.5705C5.23699 18.3451 5.70103 18.597 5.70103 19.0212V27.533C6.52967 26.0083 8.61784 24.5499 11.4352 24.5499C15.0481 24.5499 18.4952 26.9695 18.4952 32.1071V42.6805C18.4952 43.0451 18.1969 43.3434 17.8323 43.3434H13.2914C12.9268 43.3434 12.6284 43.0451 12.6284 42.6805V33.6649C12.6284 31.2453 11.4021 29.8863 9.41333 29.8863C7.32516 29.8863 5.83361 31.4442 5.83361 33.7975V42.6805C5.83361 43.0451 5.5353 43.3434 5.1707 43.3434H0.662911C0.29831 43.3434 0 43.0451 0 42.6805V23.4362C0 22.3491 0.556845 21.3414 1.47166 20.7514Z"
        fill="#005DAC"
      />
      <path
        d="M145.018 32.3921H153.57C153.106 30.3371 151.581 29.0112 149.394 29.0112C147.206 29.0112 145.615 30.2708 145.018 32.3921ZM157.826 36.1044H145.051C145.615 38.0931 147.173 39.5184 149.99 39.5184C151.707 39.5184 153.656 38.9284 155.095 38.113C155.413 37.934 155.817 38.0335 156.003 38.3516L157.408 40.7845C157.58 41.0895 157.494 41.474 157.203 41.6662C155.433 42.8396 152.827 43.8273 149.758 43.8273C142.963 43.8273 139.814 39.2532 139.814 34.3145C139.814 28.9118 143.626 24.8018 149.394 24.8018C154.597 24.8018 158.575 28.1495 158.575 33.9168C158.575 34.4471 158.542 34.9841 158.482 35.5144C158.449 35.8458 158.157 36.1044 157.826 36.1044Z"
        fill="#0096FA"
      />
      <path
        d="M173.775 41.8851C173.881 42.1967 173.755 42.5348 173.464 42.6873C172.039 43.443 170.136 43.8938 168.393 43.8938C164.15 43.8938 161.896 41.441 161.896 36.5686V29.5749H159.675C159.311 29.5749 159.012 29.2766 159.012 28.912V25.9289C159.012 25.5643 159.311 25.266 159.675 25.266H161.929V23.5093C161.929 22.3359 162.572 21.262 163.606 20.7052L166.318 19.2534C166.669 19.0611 167.1 19.3197 167.1 19.7174V25.2593H172.271C172.635 25.2593 172.933 25.5577 172.933 25.9223V28.9054C172.933 29.27 172.635 29.5683 172.271 29.5683H167.1V36.0648C167.1 38.153 167.895 39.1473 169.519 39.1473C170.401 39.1473 171.15 38.8954 171.853 38.5772C172.204 38.4115 172.622 38.5905 172.754 38.9617L173.782 41.8785L173.775 41.8851Z"
        fill="#0096FA"
      />
      <path
        d="M54.3586 33.9964C54.3586 31.6099 52.6019 29.8532 50.2155 29.8532C47.829 29.8532 46.0723 31.5767 46.0723 33.9964C46.0723 36.416 47.829 38.239 50.2155 38.239C52.6019 38.239 54.3586 36.416 54.3586 33.9964ZM59.0786 23.6284C59.4299 23.403 59.894 23.655 59.894 24.0726V41.4872C59.894 47.4203 55.353 51 49.4863 51C46.4302 51 43.467 50.0255 41.3059 48.5804C41.0275 48.3948 40.9413 48.0368 41.0872 47.7385L42.6318 44.6228C42.8041 44.2781 43.2284 44.1521 43.5598 44.3444C45.111 45.2592 47.0932 46.0547 49.2542 46.0547C52.7014 46.0547 54.3255 44.1323 54.3255 41.8452V41.4475C53.1654 42.5413 51.5081 43.2705 49.2211 43.2705C43.7189 43.2705 40.1724 39.3593 40.1724 33.9234C40.1724 28.4876 44.2493 24.477 49.4863 24.477C51.707 24.477 53.3974 25.3056 54.6238 26.4657L56.3673 25.352L59.0786 23.6218V23.6284Z"
        fill="#005DAC"
      />
      <path
        d="M177.653 41.4873H177.952C178.144 41.4873 178.263 41.4807 178.31 41.4608C178.356 41.4475 178.396 41.421 178.422 41.3812C178.449 41.3415 178.462 41.295 178.462 41.2354C178.462 41.1691 178.442 41.1161 178.409 41.0763C178.376 41.0365 178.323 41.01 178.257 41.0034C178.223 41.0034 178.124 40.9967 177.965 40.9967H177.653V41.4873ZM177.256 42.6076V40.6653H178.098C178.31 40.6653 178.462 40.6852 178.562 40.7183C178.661 40.7515 178.734 40.8178 178.794 40.9039C178.853 40.9901 178.88 41.0962 178.88 41.2089C178.88 41.3547 178.833 41.474 178.747 41.5668C178.661 41.6596 178.528 41.7193 178.356 41.7458C178.442 41.7922 178.515 41.8519 178.568 41.9116C178.621 41.9712 178.701 42.0773 178.794 42.2231L179.032 42.601H178.555L178.27 42.1767C178.164 42.0242 178.098 41.9314 178.058 41.8917C178.018 41.8519 177.978 41.8254 177.938 41.8121C177.899 41.7989 177.832 41.7922 177.74 41.7922H177.66V42.601H177.262L177.256 42.6076Z"
        fill="#005DAC"
      />
      <path
        d="M178.111 40.1613C177.249 40.1613 176.546 40.8507 176.546 41.6926C176.546 42.5345 177.249 43.2239 178.111 43.2239C178.973 43.2239 179.675 42.5345 179.675 41.6926C179.675 40.8507 178.973 40.1613 178.111 40.1613ZM178.111 43.5487C177.07 43.5487 176.221 42.7135 176.221 41.6926C176.221 40.6717 177.07 39.8364 178.111 39.8364C179.152 39.8364 180 40.6717 180 41.6926C180 42.7135 179.152 43.5487 178.111 43.5487Z"
        fill="#005DAC"
      />
      <path
        d="M88.2334 31.9745H95.9895C95.4923 30.251 94.1333 29.1572 92.1777 29.1572C90.2222 29.1572 88.83 30.1515 88.2334 31.9745ZM100.782 35.9851H88.2997C88.8963 37.7087 90.3547 38.8688 92.7744 38.8688C94.4581 38.8688 96.3474 38.312 97.7462 37.5231C98.0644 37.3441 98.4688 37.4502 98.6477 37.7684L100.285 40.5857C100.464 40.8973 100.365 41.2884 100.066 41.4807C98.2301 42.654 95.6448 43.6418 92.5092 43.6418C85.6812 43.6418 82.3998 39.1008 82.3998 34.0958C82.3998 28.6268 86.311 24.4836 92.1777 24.4836C97.4479 24.4836 101.558 27.8313 101.558 33.6318C101.558 34.1887 101.518 34.7853 101.439 35.4084C101.399 35.7399 101.114 35.9851 100.782 35.9851Z"
        fill="#005DAC"
      />
      <path
        d="M102.605 40.1151L104.508 37.2514C104.72 36.9332 105.158 36.8603 105.456 37.0923C106.841 38.1397 108.572 38.9352 110.375 38.9352C111.435 38.9352 112.33 38.5706 112.33 37.7751C112.33 37.4105 112.131 37.079 111.336 36.7476C110.441 36.4161 107.889 35.6538 106.795 35.1566C104.64 34.1622 103.381 32.6044 103.381 30.1847C103.381 26.7376 106.331 24.3843 110.938 24.3843C113.172 24.3843 115.26 24.9212 117.262 26.3796C117.547 26.5918 117.627 26.9829 117.428 27.2812L115.605 30.0654C115.413 30.3637 115.015 30.4499 114.71 30.2709C113.238 29.3959 111.813 29.0512 110.839 29.0512C109.864 29.0512 109.181 29.4821 109.181 30.0787C109.181 30.6422 109.546 31.073 110.839 31.4045C111.932 31.7028 113.689 32.2331 115.148 32.9955C117.368 34.1224 118.263 35.5477 118.263 37.6359C118.263 41.1493 115.313 43.7346 110.408 43.7346C107.438 43.7346 104.76 42.727 102.718 40.9769C102.466 40.7582 102.42 40.3869 102.605 40.1085"
        fill="#005DAC"
      />
      <path
        d="M20.6298 35.2758V25.6304C20.6298 25.2658 20.9281 24.9675 21.2927 24.9675H25.8336C26.1982 24.9675 26.4965 25.2658 26.4965 25.6304V35.3421C26.4965 36.2503 26.7617 36.9861 27.292 37.5429C27.8224 38.1064 28.5317 38.3915 29.4133 38.3915C30.295 38.3915 31.0375 38.113 31.5678 37.5628C32.0981 37.0126 32.3633 36.2702 32.3633 35.3421V25.6304C32.3633 25.2658 32.6616 24.9675 33.0262 24.9675H37.5671C37.9317 24.9675 38.23 25.2658 38.23 25.6304V35.2758C38.23 37.9473 37.4544 40.0289 35.8966 41.5071C34.3388 42.9854 32.1777 43.7279 29.42 43.7279C26.6623 43.7279 24.5012 42.9854 22.9566 41.5071C21.412 40.0289 20.6364 37.9473 20.6364 35.2758"
        fill="#005DAC"
      />
      <path
        d="M133.404 43.3367C133.039 43.3367 132.741 43.0384 132.741 42.6738V33.3002C132.741 31.2784 131.097 29.641 129.075 29.641C128.373 29.641 127.677 29.8332 127.007 30.2177C126.132 30.7215 125.416 31.9678 125.416 33.0019V42.6738C125.416 43.0384 125.118 43.3367 124.753 43.3367H120.776C120.411 43.3367 120.113 43.0384 120.113 42.6738V32.3324H120.126V32.1799C120.318 28.05 124.256 24.8083 129.102 24.8083C133.948 24.8083 137.753 27.9572 138.064 31.9744V32.107L138.084 32.1136V42.6738C138.084 43.0384 137.786 43.3367 137.421 43.3367H133.411H133.404Z"
        fill="#0096FA"
      />
      <path
        d="M136.076 10.8807L128.671 16.1442C128.538 16.237 128.386 16.3166 128.227 16.3696C128.068 16.4293 127.895 16.469 127.723 16.4889C127.551 16.5088 127.378 16.5088 127.219 16.4889C127.06 16.469 126.908 16.4293 126.775 16.363L120.332 13.2407C120.332 13.2407 120.325 13.2407 120.318 13.2407C120.318 13.2407 120.312 13.2407 120.305 13.234C120.298 13.2274 120.298 13.234 120.292 13.2274C120.292 13.2274 120.285 13.2274 120.278 13.2274C120.179 13.1677 120.106 13.1014 120.06 13.0219C120.013 12.9423 120 12.8562 120.02 12.77C120.033 12.6838 120.08 12.591 120.146 12.5048C120.219 12.4186 120.312 12.3325 120.438 12.2463L127.803 7.52637L136.069 10.894L136.076 10.8807Z"
        fill="#0096FA"
      />
      <path
        d="M131.369 2.63408L121.511 8.75274L116.062 6.353C116.062 6.353 116.049 6.34637 116.042 6.34637C116.036 6.34637 116.029 6.33975 116.023 6.33312C116.016 6.33312 116.009 6.32649 116.003 6.31986C115.996 6.31986 115.989 6.31323 115.983 6.3066C115.797 6.21379 115.671 6.09447 115.605 5.96189C115.532 5.8293 115.519 5.69009 115.559 5.53762C115.598 5.39178 115.685 5.23931 115.824 5.08684C115.963 4.93438 116.155 4.78853 116.394 4.65595L122.744 1.03646C122.97 0.910508 123.222 0.804443 123.494 0.718264C123.765 0.638715 124.044 0.579053 124.329 0.539278C124.607 0.506133 124.892 0.492875 125.151 0.512762C125.416 0.532649 125.661 0.579053 125.867 0.651973L131.376 2.62082L131.369 2.63408Z"
        fill="#0096FA"
      />
      <path
        d="M144.932 3.57551C145.045 3.62191 145.138 3.67494 145.204 3.73461C145.27 3.79427 145.31 3.86719 145.33 3.93348C145.343 4.0064 145.33 4.07932 145.297 4.15224C145.257 4.22516 145.191 4.29808 145.091 4.371L138.767 8.8589L130.527 5.69019L136.878 1.61992C136.977 1.55363 137.097 1.5006 137.229 1.46082C137.362 1.42105 137.501 1.3879 137.64 1.37464C137.779 1.35476 137.925 1.35476 138.064 1.36139C138.204 1.37464 138.33 1.39453 138.449 1.43431L144.892 3.55562H144.906C144.906 3.55562 144.912 3.55562 144.919 3.55562C144.919 3.55562 144.926 3.55562 144.932 3.55562C144.932 3.55562 144.939 3.55562 144.945 3.55562"
        fill="#0096FA"
      />
      <path
        d="M152.595 10.2179C152.88 10.3373 153.119 10.4765 153.298 10.6422C153.477 10.8013 153.596 10.9869 153.656 11.1725C153.716 11.3648 153.709 11.557 153.636 11.7493C153.563 11.9415 153.424 12.1337 153.212 12.3127L146.908 17.6028C146.656 17.8083 146.351 17.9806 146.013 18.1132C145.675 18.2458 145.303 18.332 144.919 18.3784C144.534 18.4248 144.143 18.4248 143.772 18.3784C143.394 18.332 143.036 18.2392 142.711 18.0933L135.141 14.7589L144.992 7.5L152.463 10.1649C152.463 10.1649 152.489 10.1715 152.496 10.1782C152.509 10.1782 152.522 10.1848 152.529 10.1914C152.542 10.1914 152.556 10.198 152.562 10.2047C152.575 10.2047 152.582 10.2113 152.595 10.2179Z"
        fill="#0096FA"
      />
      <path
        d="M63.719 20.7514L67.133 18.5705C67.4843 18.3451 67.9483 18.597 67.9483 19.0212V27.533C68.777 26.0083 70.8652 24.5499 73.6825 24.5499C77.2954 24.5499 80.7425 26.9695 80.7425 32.1071V42.6805C80.7425 43.0451 80.4442 43.3434 80.0796 43.3434H75.5387C75.1741 43.3434 74.8758 43.0451 74.8758 42.6805V33.6649C74.8758 31.2453 73.6494 29.8863 71.6606 29.8863C69.5725 29.8863 68.0809 31.4442 68.0809 33.7975V42.6805C68.0809 43.0451 67.7826 43.3434 67.418 43.3434H62.9102C62.5456 43.3434 62.2473 43.0451 62.2473 42.6805V23.4362C62.2473 22.3491 62.8042 21.3414 63.719 20.7514Z"
        fill="#005DAC"
      />
    </svg>
  );
}
