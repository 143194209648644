'use client';

import { useContext } from 'react';
import styles from './styles.module.scss';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';

export default function HomepagePerfectPlan() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.perfectPlan}>
      <div>
        <h2>Find your perfect Hughesnet internet plan</h2>
        <h3>
          Our plans service across the nation so you can stay connected from
          wherever you live.
        </h3>
        <h3>Check available plans in your area</h3>
        <a
          href={`tel:${fuseNumber || defaultFuseNumber}`}
          className={styles.phoneNumber}
        >
          Call <PhoneNumber />
        </a>
      </div>
    </section>
  );
}
