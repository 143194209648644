'use client';

import { useContext } from 'react';
import styles from './styles.module.scss';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';

export default function Hero() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.hero}>
      <div className="container">
        <h1>
          Hughesnet is here! Now you can enjoy unlimited data at up to 100 Mbps
          <sup></sup> wherever you live.
        </h1>
        <h2>
          Experience high-speed satellite internet with Hughesnet. Enjoy speeds
          up to 100 Mbps<sup></sup>, unlimited data, and nationwide
          availability.
        </h2>
        <a href={`tel:${fuseNumber || defaultFuseNumber}`}>
          Call <PhoneNumber />
        </a>
      </div>
    </section>
  );
}
