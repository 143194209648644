'use client';

import { createContext, useState, useEffect } from 'react';
import { logError } from '@red-digital/bricks';

export const FuseContext = createContext();

export function FuseProvider({ children }) {
  const [fuseNumber, setFuseNumber] = useState('');
  const defaultFuseNumber = '8778796866';

  const fuseLeaseRecieved = (setFuseNumber) => {
    return (leases) => {
      setFuseNumber(leases?.[0]?.dnis);
      return leases?.[0]?.dnis;
    };
  };

  const fuseError = (setFuseNumber, defaultFuseNumber) => {
    return () => {
      setFuseNumber(defaultFuseNumber);
      logError(new Error('Fuse failed to load'));
      return true;
    };
  };

  useEffect(() => {
    try {
      window?.cohesion('fuse:leasesReceived', fuseLeaseRecieved(setFuseNumber));

      window?.cohesion(
        'fuse:error',
        fuseError(setFuseNumber, defaultFuseNumber),
      );
    } catch (error) {
      logError(`cohesion:catch: ${error}`);
    }
  }, []);

  return (
    <FuseContext.Provider value={{ fuseNumber, defaultFuseNumber }}>
      {children}
    </FuseContext.Provider>
  );
}
