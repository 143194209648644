'use client';

import styles from './styles.module.scss';
import { Carousel } from '@red-digital/bricks';
import { useContext } from 'react';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';

const carouselData = [
  {
    title: 'Connect',
    content:
      'Hughesnet is faster than ever, with download speeds up to 100 Mbps<sup>1</sup> and upload speeds of 5 Mbps!<sup>1</sup>',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="61" viewBox="0 0 64 61" fill="none">
            <path d="M63.3535 6.99262C63.3506 7.00421 63.3477 7.01579 63.3477 7.02738C63.1505 5.2578 62.5474 3.78363 61.5645 2.69465C60.2133 1.18573 58.3257 0.429822 56.1279 0.505124C52.6804 0.580425 48.2847 2.54984 43.4135 6.19906C38.5974 9.78746 33.6335 14.6994 29.0581 20.3934C24.1028 26.4522 20.548 31.9232 17.8775 37.6026C17.5992 38.1558 17.5644 38.7524 17.7703 39.3722C17.9471 39.8993 18.3589 40.3888 18.9011 40.7131L18.9446 40.7363C19.4984 41.0143 20.0957 41.0491 20.7133 40.8435C21.241 40.6668 21.731 40.2555 22.0557 39.7139L22.0847 39.6618C24.6334 34.3704 27.9997 29.1833 32.6824 23.3359C37.0897 17.8852 41.7782 13.2745 46.2406 10.0018C50.3347 6.95207 53.875 5.27228 56.2091 5.27228C57.1427 5.27228 57.6385 5.44026 58.0503 5.90365C58.4359 6.32939 58.8795 7.32569 58.7983 9.64555C58.6621 11.7337 57.9894 14.32 56.7977 17.3321C54.22 23.8427 49.7982 31.1093 44.3501 37.7909C39.9428 43.2415 35.2543 47.8523 30.792 51.125C26.6978 54.1747 23.1575 55.8545 20.8234 55.8545C19.9681 55.8545 19.3534 55.646 18.988 55.2289C18.5473 54.7337 18.2371 54.2819 18.2371 52.9351C18.2371 51.6637 17.1614 50.5892 15.8885 50.5892C14.6156 50.5892 13.5399 51.6637 13.5399 52.9554C13.6124 55.2521 14.1894 56.845 15.468 58.2815C16.7351 59.7035 18.5183 60.4595 20.6321 60.471C20.6031 60.4797 20.577 60.4913 20.5509 60.5C28.9972 60.2857 36.6055 57.5285 43.6977 51.6029V58.3336C43.6977 59.5471 44.6516 60.5 45.8665 60.5C47.0814 60.5 48.0353 59.5471 48.0353 58.3336V47.5105C49.3662 46.1088 50.6797 44.5911 51.9787 42.9374C60.4337 32.1635 64.3277 17.5435 63.3535 6.99262Z" fill="white"></path>
            <path d="M26.0744 49.5205C25.9759 48.9731 25.6511 48.4807 25.1814 48.1679L5.5198 34.8657L7.55816 31.842L10.1706 33.4726C11.1507 34.0431 12.3626 33.768 12.9976 32.8151C13.5688 31.8362 13.2934 30.6256 12.3482 29.9971L8.03948 27.2457C7.07974 26.6868 5.87064 26.9301 5.22405 27.8163L0.851589 34.2256C0.54714 34.6803 0.445657 35.164 0.526843 35.7461L0.538441 35.7924C0.66312 36.166 0.889282 36.7018 1.41699 37.0523L22.7661 51.4783C23.0618 51.7013 23.4475 51.8113 23.9114 51.8113C24.4971 51.8113 25.1176 51.5449 25.7033 51.0438L25.7439 51.0004C26.0512 50.5776 26.1701 50.0505 26.0744 49.5205Z" fill="white"></path>
          </svg>`,
  },
  {
    title: 'Stream',
    content:
      'Every plan comes with unlimited data, so you’ll never get cut off or pay overage fees.',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
            <path d="M42.2186 27.7367L25.2005 17.9094C24.5972 17.5595 23.98 17.3832 23.3684 17.3832C21.8972 17.3832 20.4095 18.5128 20.4095 20.6755V40.3274C20.4095 42.4874 21.8972 43.617 23.3684 43.617C23.98 43.617 24.5972 43.4407 25.2005 43.0908L42.2186 33.2662C43.3399 32.6187 43.9846 31.6104 43.9846 30.5001C43.9846 29.3925 43.3399 28.3842 42.2186 27.7367Z" fill="white"></path>
            <path d="M30 0.5C13.4585 0.5 0 13.9585 0 30.5C0 47.0415 13.4585 60.5 30 60.5C46.5415 60.5 60 47.0415 60 30.5C60 13.9585 46.5415 0.5 30 0.5ZM30 55.2048C16.3789 55.2048 5.29801 44.1238 5.29801 30.5028C5.29801 16.8817 16.3789 5.80076 30 5.80076C43.6211 5.80076 54.702 16.8817 54.702 30.5028C54.702 44.1211 43.6211 55.2048 30 55.2048Z" fill="white" ></path>
          </svg>`,
  },
  {
    title: 'Play',
    content:
      'Enjoy online gaming with a low-latency Fusion plan that delivers a more responsive connection.',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
            <path d="M59.4524 4.7863C59.0963 4.43024 58.6234 4.23182 58.1205 4.23182H47.7513V2.3863C47.754 1.34802 46.9115 0.502718 45.8705 0.5H14.135C13.0967 0.5 12.2514 1.3453 12.2514 2.3863V4.23182H1.88495C0.846675 4.23182 0.00137523 5.07712 -0.00134277 6.11812V14.6336C0.00681125 22.0022 5.7554 27.979 13.1049 28.2862C14.6813 33.3988 18.4023 37.5138 23.3327 39.5985V41.1152C23.3273 45.9233 19.4107 49.84 14.6052 49.8454H14.5998C13.5642 49.8454 12.7189 50.688 12.7162 51.729V58.6137C12.7162 59.652 13.5588 60.4973 14.6052 60.5H45.4003C46.4385 60.5 47.2811 59.6574 47.2838 58.6137V51.729C47.2838 50.6907 46.4385 49.8481 45.3975 49.8481C40.5894 49.8427 36.6727 45.9233 36.6673 41.1179V39.6012C41.5978 37.5193 45.3187 33.4042 46.8979 28.2889C54.2474 27.9818 59.9932 22.0022 60.0014 14.6364V6.11812C60.0014 5.61529 59.8057 5.14236 59.4524 4.7863ZM56.2315 8.0017V14.6336C56.2234 19.5369 52.5894 23.7227 47.7513 24.4293V8.0017H56.2315ZM16.4861 56.7301V53.618H43.5167V56.7301H16.4861ZM36.4607 49.8454H23.5448C25.8116 47.527 27.1053 44.3632 27.1053 41.1152V40.6776C28.0159 40.8243 28.9427 40.9005 29.8641 40.9005H30.1386C31.06 40.9005 31.9869 40.8243 32.8974 40.6776V41.1152C32.8974 44.3659 34.1939 47.527 36.4607 49.8454ZM34.2619 36.4755C34.232 36.4837 34.2021 36.4946 34.1885 36.5C32.8784 36.9186 31.514 37.1306 30.1386 37.1306H29.8641C28.4888 37.1306 27.1271 36.9186 25.8306 36.5054C25.8007 36.4918 25.7708 36.481 25.7518 36.4755C19.8619 34.4479 15.9534 28.9032 16.024 22.6735V4.38675H43.9842V22.6762C44.0521 28.9032 40.1409 34.4506 34.2619 36.4755ZM12.2514 8.0017V24.4293C7.41338 23.7199 3.77941 19.5369 3.77125 14.6336V8.0017H12.2514Z" fill="white"></path>
            <path d="M20.5061 17.8899L23.4116 21.3282L23.2132 25.6961C23.186 26.2777 23.4497 26.8322 23.9145 27.1801C24.2162 27.403 24.5831 27.5226 24.9582 27.5253C25.1566 27.5253 25.3523 27.4927 25.5398 27.4247L30.0028 25.8646L34.4658 27.4247C35.3736 27.7482 36.3711 27.2725 36.6945 26.3647C36.7706 26.15 36.8033 25.9244 36.7924 25.6961L36.594 21.3282L39.4968 17.8899C40.1192 17.1561 40.0295 16.0553 39.2957 15.4302C39.0945 15.2589 38.8581 15.1366 38.6053 15.0714L34.039 13.8755L31.4325 10.1844C30.8753 9.39346 29.7826 9.20592 28.9917 9.76311C28.8286 9.87726 28.6873 10.0213 28.5704 10.1844L25.9638 13.8755L21.3976 15.0714C20.4653 15.3133 19.9054 16.2673 20.1473 17.1996C20.2153 17.4523 20.3376 17.6888 20.5061 17.8899ZM27.4696 17.099C27.8692 16.993 28.2171 16.7511 28.459 16.4141L30.0028 14.2261L31.5466 16.4141C31.7858 16.7511 32.1364 16.993 32.536 17.099L35.0338 17.7513L33.4819 19.5914C33.1965 19.9285 33.047 20.3606 33.0714 20.8009L33.1802 23.2662L30.5763 22.3584C30.2039 22.2252 29.7962 22.2252 29.4239 22.3584L26.8227 23.2662L26.9342 20.8009C26.9532 20.3606 26.8064 19.9285 26.5238 19.5914L24.9718 17.7513L27.4696 17.099Z" fill="white"></path>
          </svg>`,
  },
];

export default function HomepageValueProps() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.valueProps}>
      <div className={styles.container}>
        <h2>Fast speeds. Unlimited data.</h2>
        <h3>
          Hughesnet plans come with speeds up to 100 Mbps<sup>1</sup>
        </h3>
        <p>
          Hughesnet satellite home internet delivers broadband-level speeds and
          keeps you connected to who and what matters most, no matter where you
          live.
        </p>
        <h3>What can you do with a Hughesnet plan?</h3>
      </div>
      <div className={styles.valuePropsSlides}>
        <Carousel
          config={{
            autoplay: { enabled: true, transitionSpeed: 500, delayTime: 3000 },
            animationType: 'slide',
            slidesToShow: 1,
            slidesToMove: 1,
            controls: {
              type: 'inside',
              pagination: {
                enabled: false,
              },
              buttons: {
                enabled: false,
              },
            },
            gap: 20,
            overflow: 'hidden',
          }}
          className="valuePropsCarousel"
        >
          {carouselData.map((item, index) => (
            <div key={index} className={styles.valuePropsSlide}>
              <div>
                <div dangerouslySetInnerHTML={{ __html: item.svg }} />
                <p dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
              <div className={styles.valuePropsContent}>
                <p dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.valuePropsCards}>
        {carouselData.map((item, index) => (
          <div key={index} className={styles.valuePropsCard}>
            <div>
              <div dangerouslySetInnerHTML={{ __html: item.svg }} />
              <p dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            <div className={styles.valuePropsContent}>
              <p dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </div>
        ))}
      </div>
      <a
        className={styles.phoneNumber}
        href={`tel:${fuseNumber || defaultFuseNumber}`}
      >
        Call <PhoneNumber />
      </a>
    </section>
  );
}
