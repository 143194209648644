'use client';

import { useContext } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';

const cardData = [
  {
    title: 'Check data usage',
    content:
      'Keep track of your data cycle in real time with the data usage meter.',
  },
  {
    title: 'Manage your home Wi-Fi network',
    content:
      'Check your Wi-Fi network speed, manage network prioritization, change your Wi-Fi network password and more!',
  },
  {
    title: 'Access your account',
    content:
      'View and pay your bill, check account information and manage your profile.',
  },
];

export default function StayOnTrack() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.stayOnTrack}>
      <div className={styles.container}>
        <h2>Stay on track with the Hughesnet Mobile App</h2>
        <p>
          Check your account at any time with the Hughesnet Mobile App. Download
          today on your smartphone.
        </p>
        <a
          href={`tel:${fuseNumber || defaultFuseNumber}`}
          className={styles.phoneNumber}
        >
          Call to order today: <PhoneNumber dashes />
        </a>
        <div className={styles.cards}>
          {cardData.map((item, index) => (
            <div key={index} className={styles.card}>
              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.appStores}>
        <Image
          src="/images/apple-app-store.png"
          width={118}
          height={44}
          alt="Apple App Store logo"
        />
        <Image
          src="/images/google-play-store.png"
          width={118}
          height={44}
          alt="Google Play Store logo"
        />
      </div>
    </section>
  );
}
