'use client';

import { useContext } from 'react';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';

import styles from './styles.module.scss';

export default function Trust() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.trust}>
      <div className={styles.container}>
        <h2>You can count on us.</h2>
        <h3>
          Over 1 million customers trust Hughesnet as their satellite internet
          service provider.
        </h3>
        <p>
          <a
            className={styles.phoneNumber}
            href={`tel:${fuseNumber || defaultFuseNumber}`}
          >
            Call <PhoneNumber />
          </a>
        </p>
      </div>
    </section>
  );
}
