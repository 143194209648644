'use client';

import { useContext } from 'react';
import { FuseContext } from '~/providers/FuseProvider';
import PhoneNumber from '~/components/PhoneNumber';
import styles from './styles.module.scss';
import Image from 'next/image';

export default function FiveReasons() {
  const { fuseNumber, defaultFuseNumber } = useContext(FuseContext);

  return (
    <section className={styles.fiveReasons}>
      <div className={styles.container}>
        <div className={styles.barChart}>
          <Image
            src="/images/bar-chart.png"
            width={420}
            height={330}
            alt="Bar chart"
          />
        </div>
        <div className={styles.list}>
          <h2>Top 5 Reasons to Get Hughesnet</h2>
          <p>
            <strong>Faster Speeds:</strong> Plans with speeds up to 100 Mbps.
            <sup>1</sup>
          </p>
          <p>
            <strong>Unlimited Data:</strong> All plans include unlimited data,
            <sup>2</sup> so you can do more of what you love online.
          </p>
          <p>
            <strong>Whole Home Wi-Fi:</strong> All plans include advanced,
            built-in Wi-Fi. Add Mesh Wi-Fi Nodes to eliminate dead zones.
          </p>
          <p>
            <strong>Reduced Latency:</strong> The Fusion plan combines satellite
            and wireless technologies for a more responsive internet experience.
            Check for availability!
          </p>
          <p>
            <strong>Available Where You Live:</strong> Even in places that are
            beyond the reach of cable and fiber.
          </p>
          <a
            className={styles.phoneNumber}
            href={`tel:${fuseNumber || defaultFuseNumber}`}
          >
            Call <PhoneNumber />
          </a>
        </div>
      </div>
    </section>
  );
}
